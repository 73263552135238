import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/User.context';

export default function Brand() {

  const location = useLocation();
  const history  = useNavigate();
  const user     = useContext(UserContext)

  const [brandsData, setBrandsData] = useState();

  //console.log('location', location);

  const onLoad = async () => {
      const brands = await localforage.getItem('brands');
      if(brands !== null) setBrandsData(brands);

  }

  useEffect(()=>{
    onLoad();
  },[])

  const handleSelect = async (element) => {
    localforage.setItem('selectedBrand', element);
    user.updateUser({selectedBrand: element})
    //console.log('brand Updated ', user.selectedBrand);
    history("/scanner", {state : element});
  }

  return (
    <div>
       <div className='navbar HeadingH24R'>
            <div className='cp' onClick={()=>{history('/')}}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.5002 0.516594C21.346 0.362068 21.1629 0.239471 20.9612 0.155823C20.7596 0.0721743 20.5435 0.0291174 20.3252 0.0291174C20.1069 0.0291174 19.8908 0.0721743 19.6891 0.155823C19.4875 0.239471 19.3044 0.362068 19.1502 0.516594L11.0002 8.64993L2.8502 0.499927C2.69602 0.345401 2.51287 0.222805 2.31125 0.139156C2.10963 0.0555081 1.89348 0.0124512 1.6752 0.0124512C1.45691 0.0124512 1.24077 0.0555081 1.03914 0.139156C0.837518 0.222805 0.654374 0.345401 0.500195 0.499927C-0.149805 1.14993 -0.149805 2.19993 0.500195 2.84993L8.6502 10.9999L0.500195 19.1499C-0.149805 19.7999 -0.149805 20.8499 0.500195 21.4999C1.1502 22.1499 2.2002 22.1499 2.8502 21.4999L11.0002 13.3499L19.1502 21.4999C19.8002 22.1499 20.8502 22.1499 21.5002 21.4999C22.1502 20.8499 22.1502 19.7999 21.5002 19.1499L13.3502 10.9999L21.5002 2.84993C22.1335 2.21659 22.1335 1.14993 21.5002 0.516594V0.516594Z" fill="black"/>
                </svg>
            </div>

            <div className='text-center'>
                Select Brand
            </div>

        </div>


        <div className='brands'>
          <div className='selectbrand caption mb-20'>Select brand you want to login with:</div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '20px' }}>
              {brandsData && brandsData.length > 0 && brandsData.map((element, idx) => (
                <div onClick={()=>{handleSelect(element)}} className='cp'
                  style={{
                    padding: '26px 38px',
                    borderRadius: '12px',
                    background: '#FFF',
                    boxShadow: '0px 14px 24px 0px rgba(0, 0, 0, 0.05)',
                    transition: 'box-shadow 0.3s ease',
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'}
                  onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}
                >
                  <div><img className='borderRadius-50' src={element.brandLogo} alt="Brand Logo" width="42px" height="42px" /></div>
                  <div style={{
                    color: '#212529',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '16.8px',
                    textTransform: 'capitalize'
                  }}>{element.brandName}</div>
                </div>
              ))}
            </div>


        </div>
    </div>
  )
}
