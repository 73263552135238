// CSS (do not change the orders)
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/global.scss'

// Pages
import Login from './pages/auth/LoginPage'
import CustomerPage from './pages/auth/CustomerPage';
import FlowData from './pages/FlowData';
import Spend from './pages/Spend';
import ReadFlowData from './pages/ReadFlowData';
import Deal from './pages/Deal';
import Brand from './pages/Brand';
import { CentralGuard } from './utils/RouteGuards';

import { UserContextComponent } from './context/User.context';

import {
  BrowserRouter,
  Routes,
  Route,
  withRouter,
  Navigate,
} from "react-router-dom";
import CustomToasters from './components/CustomToasters';
import PngIcons from './assets/images/pngIcons';
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
        <title>{'Business Connector'}</title>
        <link rel="icon" type="image/png" href={PngIcons.x6} sizes="32x32" />
        <link rel="apple-touch-icon" sizes="57x57"  href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="114x114"  href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="72x72" href={PngIcons.x6} />    
        <link rel="apple-touch-icon" sizes="144x144" href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="60x60"  href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="120x120" href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="76x76"  href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="152x152"  href={PngIcons.x6} />
        <link rel="apple-touch-icon" sizes="180x180"  href={PngIcons.x6} />
      </Helmet>
      <BrowserRouter>
      <UserContextComponent>
        <CustomToasters/>
        <Routes>
          <Route exact path = '/'            element   = {<CentralGuard><Login/></CentralGuard>}/>
          <Route path       = '/brand'       element   = {<CentralGuard><Brand/></CentralGuard>}/>
          <Route path       = '/scanner'     element   = {<CentralGuard><CustomerPage/></CentralGuard>}/>
          <Route path       = '/flow'        element   = {<FlowData/>}/>
          <Route path       = '/readflows'   element   = {<ReadFlowData/>}/>
          <Route path       = '/deal'        element   = {<Deal/>}/>
          <Route path       = '/spend'       element   = {<Spend/>}/>
          <Route path       = "*"            element   = {<CentralGuard><Login/></CentralGuard>} />
        </Routes>
      </UserContextComponent>
      </BrowserRouter>
    </div>
  );
}

export default App;
