import "./login.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { LoginVld } from "../../validations/Login";
import localforage from "localforage";
import { useNavigate } from "react-router-dom";
import { BusinessService } from "../../services";
import { UserContext } from "../../context/User.context";
import { BrandContext } from "../../context/Brand.context";
import { CircularProgress } from "@mui/material";

export default function Login() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  const user = useContext(UserContext);
  const brand = useContext(BrandContext);
  const [brandsData, setBrandsData] = useState();
  const history = useNavigate();


  //console.log('brand ', brand);
  //console.log('user ', user);


  const initValues = {
    userId: "",
  };

  async function handleSubmitFunc(element) {
    setLoad(true);
    const {response, error} = await BusinessService.Login({query: {idKey: formik.values.userId, brand: true}, toaster : true});
    console.log('response ', response);
    console.log('responsebrands',response.data.brands.length);


    // user.brands && user.brands.length == 1

    if(response && response.success){
      
      if(response.data.brands && response.data.brands.length == 1){
         await localforage.setItem("brands", response.data.brands);

         
        user.updateUser({...response.data.user, brands: response.data.brands});
        history('/scanner')
      }
      else{
      await localforage.setItem("brands", response.data.brands);
      user.updateUser({...response.data.user, brands: response.data.brands});
      history('/brand')
      }
    }
    else{
        setError(true)
    }
    setLoad(false);
  }


  const handlKeyDown = (e) => {
    if(e.code === 'Enter'){ handleSubmitFunc()}
  }


  const formik = useFormik({
    initialValues: { ...initValues },
    validationSchema: LoginVld,
    isInitialValid: false,
  });

  return (
    <div className="middle" style={{height: '90vh'}}>
    <div style={{gap:"151px", padding: '121px 73px 121px 73px', display: "flex", borderRadius: '27px', background: '#FFF', boxShadow: '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}}>
      <div className="w-100">
        <div style={{marginBottom: '40px',color: '#000',fontFamily: 'Inter',fontSize: '36px',fontStyle: 'normal',fontWeight: '700',lineHeight: '32px',letterSpacing: '0.1px'}}>Login</div>
        <div style={{color: '#6C757D',fontFamily: 'Inter',fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: '26px'}}>Enter your user id assigned to you by the business owner.</div>
      </div>
      <div className="w-100">
        <div style={{marginBottom: '14px', color: '#141414',fontFamily: 'Inter',fontSize: '16px',fontStyle: 'normal',fontWeight: '700',lineHeight: '20px',letterSpacing: '0.1px'}}>User ID</div>
        <input type        = "text"name        = "userId"onChange    = {formik.handleChange}value       = {formik.values.userId}className       = "form-control text-field"id          = "exampleInputPassword1"placeholder = "Enter your user id"onKeyDown={(e)=>handlKeyDown(e)}/>
        <button
              className="btn btn-primary button d-flex center mt-18"
              disabled={!formik.isValid || load}
              onClick={handleSubmitFunc}
            >
              {load && <CircularProgress size={14} color={"inherit"} />}
              Continue
            </button>
      </div>
    </div>
    {/* <div className="container-fluid pl-200 pr-200 pt-100 pb-100 for-responsiveLogin" id="for-responsive">
      <div className="res-login">
        <div className="card-body shadow res-card">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1" className="sign-in">
                <b>Login</b>
              </label>
              <small id="emailHelp" className="form-text text-muted">
                Enter your user id assigned to you by the business owner.
              </small>
            </div>
            <div className="form-group res-id">
              <label htmlFor="exampleInputPassword1" className="user-id mt-50px">
                <b>User ID</b>
              </label>
              <input
                type        = "text"
                name        = "userId"
                onChange    = {formik.handleChange}
                value       = {formik.values.userId}
                className       = "form-control text-field"
                id          = "exampleInputPassword1"
                placeholder = "Enter your user id"
                onKeyDown={(e)=>handlKeyDown(e)}
              />
              <span className="error">{formik.errors.userId ? formik.errors.userId : error ? 'Invalid User ID' : ''}</span>
            </div>
            <div className="cont-btn">
            <button
              className="btn btn-primary button d-flex center"
              disabled={!formik.isValid || load}
              onClick={handleSubmitFunc}
            >
              {load && <CircularProgress size={14} color={"inherit"} />}
              Continue
            </button>
            </div>
        </div>
      </div>
    </div> */}
    </div>
  );
}
